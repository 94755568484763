/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

import '@/tailwind.css'
import '@/global.d.ts'

// Plugins
import { registerPlugins } from '@/plugins'

// Components
import App from '@/App.vue';

// Composables
import { createApp } from 'vue';

import router from '@/router'

//import { createRouter, createWebHistory } from 'vue-router';
// fonts
import './assets/fonts/font.css'

import './styles/common.css'

import { createHead } from '@vueuse/head'

const app = createApp(App)
const head = createHead()

app.use(router)
app.use(head)
app.mount('#app')

//use only once, included in src\plugin\index.ts  
//app.use(router)

registerPlugins(app)

//or a similar entry file
  if (!('requestIdleCallback' in Window)) {
    window.requestIdleCallback = function (cb: Function) {
      return setTimeout(() => {
        const start = Date.now();
        cb({
          didTimeout: false,
          timeRemaining: function () {
            return Math.max(0, 50 - (Date.now() - start));
          }
        });
      }, 1) as unknown as number; // Cast to number
    };
  
    window.cancelIdleCallback = function (id: number) {
      clearTimeout(id);
    };
  }
