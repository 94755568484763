// Composables

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {ref} from 'vue';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

//import ResetPassword from '@/views/ResetPassword.vue'
/*
const routes: Array<RouteRecordRaw> = [
  {
    path: '/reset-password/:token', // URL pattern with token as a parameter
    name: 'ResetPassword',
    component: ResetPassword,
    props: true // Enable passing route params as props to the component
  }
  */
const routes: RouteRecordRaw[] = [
      {
        path: '/',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (Home-[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: '',
            name: 'HomeDefault',
            // route level code-splitting
            // this generates a separate chunk (Home-[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/home/HomeDefault.vue'),
          },
          {
            path: '/home_purchase_mortgage',
            name: 'HomePurchaseMortgage',
            component: () => import('@/views/home/HomePurchaseMortgage.vue'),
          },
          {
            path: '/mortgage_refinance',
            name: 'HomeRefinance',
            component: () => import('@/views/home/HomeRefinance.vue'),
          },

          {
            path: '/EMS_feedback',
            name: 'EMS_feedback',
            component: () => import('@/views/home/EMS_feedback.vue'),
          },
          {
            path: '/EMS_services',
            name: 'EMS_services',
            component: () => import('@/views/home/EMS_services.vue'),
          },
          {
            path: '/Feedback',
            name: 'Feedback',
            component: () => import('@/views/home/Feedback.vue'),
          },{
            path: '/RateTable',
            name: 'RateTable',
            component: () => import('@/views/home/RateTable.vue'),
          },
          {
            path: '/FAQ',
            name: 'FAQ',
            component: () => import('@/views/home/FAQ.vue'),
          },
          {
            path: '/About',
            name: 'ABOUT',
            component: () => import('@/views/home/About.vue'),
          },
          {
            path: '/EMS_whyus',
            name: 'EMSwhyus',
            component: () => import('@/views/home/EMS_whyus.vue'),
          },{
            path: '/App_status',
            name: 'Appstatus',
            component: () => import('@/views/home/App_status.vue'),
          },
        ]
      }, {
        path: '/Apply',
        name: 'Apply',
        component: () => import('@/views/Apply.vue'),
      }, {
        path: '/Register',
        name: 'Register',
        component: () => import('@/views/Register.vue'),
      }, {
        path: '/Login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
      },{
        path: '/Logout',
        name: 'Logout',
        component: () => import('@/views/Logout.vue'),
      },{
        path: '/Thankyou',
        name: 'Thankyou',
        component: () => import('@/views/Thankyou.vue'),
      },{
        path: '/ResetPassword/:token*', //  RL pattern with token as a parameter
        //path: '/ResetPassword',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword.vue'),
        //component: ResetPassword,
        props: true // Enable passing route params as props to the component
      },
      {
        path: "/:catchAll(.*)",
        name: 'NotFound',
        redirect: '/'
      }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const API_URL = import.meta.env.VITE_APP_API_URL;
const sessionKey = ref(localStorage.getItem('sessionKey') || uuidv4());

router.beforeEach((to, from, next) => {
  // Log page visit
  const pageVisitData = {
    session_id: sessionKey.value,
    routepath: to.path,
    routename: to.name,
    timestamp: new Date().toISOString(),
    referrer: from.fullPath || document.referrer || 'direct',
    ip_address: ''
  };

  const response = axios.post(`${API_URL}/api/pagevisits`, pageVisitData)
    .then(response => {
      console.log('PV logged:', response.data);
    })
    .catch(error => {
      console.error('Error logging PV:', error);
    });

  next();
  });

//  history: createWebHistory(process.env.BASE_URL),
// 每次跳转都回到页面顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

export default router;
